import React from 'react';
import { BrowserRouter, NavLink, Link, Outlet, Route, Routes } from 'react-router-dom';
import { Typography, Layout } from 'antd';
import { Button, Menu } from 'antd';

import {
  AppstoreOutlined,
  ContainerOutlined,
  DesktopOutlined,
  MailOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PieChartOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { ItemType } from 'antd/es/menu/hooks/useItems';
type MenuItem = Required<MenuProps>['items'][number];


const headerStyle: React.CSSProperties = {
  textAlign: 'center',
  backgroundColor: '#7dbcea',
  height: '20pt',
}

const siderStyle: React.CSSProperties = {
  textAlign: 'center',
  lineHeight: '120px',
  color: '#fff',
  backgroundColor: 'white',
  height: 'calc(100vh - 70pt)',
};


const contentStyle: React.CSSProperties = {
  textAlign: 'left',
  minHeight: 120,
  lineHeight: '120px',
  //color: '#fff',
  //backgroundColor: '#108ee9',
};


function getItem(
  label: React.ReactNode,
  key: React.Key,
  url: string,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {

  let item = {
    key: key,
    icon: icon,
    children: children,
    label: <Link to={url}>{label}</Link>,
  } as MenuItem;

  return item;
}

const items: MenuItem[] = [
  getItem('Strona główna', '1', '/', <PieChartOutlined />),
  getItem('Lekcje', '2', '/chapters', <DesktopOutlined />),
  getItem('Raport', '3', '/report', <ContainerOutlined />),
  getItem('Słówka', '4', '/words', <ContainerOutlined />),
];


export function SideMenu() {
  return (
    <Menu
      defaultSelectedKeys={['1']}
      mode="inline"
      theme="light"
      items={items}
    />
  )
}
