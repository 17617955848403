import { Typography, Card, List, Button, Divider, Spin } from 'antd';
import { useState } from 'react';
import { Model } from '../api';
import { LessonsService } from '../api';
import { ChaptersCardList } from '../components';
import { useFetchChapters } from '../hooks';



const c_chapters = LessonsService.Example.chapters;

function Content()
{
    let {isLoading:isChaptersLoading, error:chaptersLoadingError, data:chapters } = useFetchChapters();

    if (isChaptersLoading) {
        return <Spin tip="Wczytuję..." size='large'/>
    }
    
    return <ChaptersCardList chapters={chapters || []} />
}

export function ChaptersPage() {
    return <>
        <Typography.Title level={2}>
            Rozdziały
        </Typography.Title>
        <Content/>
    </>
}