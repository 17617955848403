import { Model } from './model';

export namespace LessonsService {

    export namespace Example {


        export const chapters = [
            {
                id: 'CH-1',
                title: 'Lekcje 2022',
                timestamp: Date.now() - 10,
                lessons: [
                    {
                        title: 'Odmiana czasowników'
                        , score: 40.3
                        , id: 'CH-1-L-1'
                        , timestamp: Date.now() - 50000,
                    },
                    {
                        title: 'Podstawowe rzeczowniki'
                        , score: 40.3
                        , id: 'CH-1-L-2'
                        , timestamp: Date.now() - 50001,
                    }
                ]
            } as Model.Chapter,
            {
                id: 'CH-2',
                title: '2023.Q1',
                timestamp: Date.now() - 500,
                lessons: [
                    {
                        title: '1. Czas przeszły'
                        , score: 90.3
                        , id: 'CH-2-L-1'
                        , timestamp: Date.now() - 50001,
                    },
                    {
                        title: '2. Odmiana czasowników'
                        , score: 40.3
                        , id: 'CH-2-L-2'
                        , timestamp: Date.now() - 500001,
                    },
                    {
                        title: '3. Odmiana czasowników'
                        , score: 40.3
                        , id: 'CH-2-L-3'
                        , timestamp: Date.now() - 570001,
                    },
                    {
                        title: '4. Odmiana czasowników'
                        , score: 40.3
                        , id: 'CH-2-L-4'
                        , timestamp: Date.now() - 500901,
                    },
                    {
                        title: '5. Odmiana czasowników'
                        , score: 40.3
                        , id: 'CH-2-L-4'
                        , timestamp: Date.now() - 5007901,
                    },
                    {
                        title: '6. Czas przeszły'
                        , score: 90.3
                        , id: 'CH-2-L-5'
                        , timestamp: Date.now() - 5006701,
                    },
                    {
                        title: '7. Odmiana czasowników'
                        , score: 40.3
                        , id: 'CH-2-L-6'
                        , timestamp: Date.now() - 5980001,
                    },
                    {
                        title: '8. Odmiana czasowników'
                        , score: 40.3
                        , id: 'CH-2-L-7'
                        , timestamp: Date.now() - 5006301,
                    },
                    {
                        title: '9. Odmiana czasowników'
                        , score: 40.3
                        , id: 'CH-2-L-8'
                        , timestamp: Date.now() - 5003501,
                    },
                    {
                        title: '10. Odmiana czasowników'
                        , score: 40.3
                        , id: 'CH-2-L-9'
                        , timestamp: Date.now() - 500567501,
                    },
                    {
                        title: '11. Odmiana czasowników'
                        , score: 40.3
                        , id: 'CH-2-L-10'
                        , timestamp: Date.now() - 50056701,
                    },
                    {
                        title: '12. Odmiana czasowników'
                        , score: 40.3
                        , id: 'CH-2-L-11'
                        , timestamp: Date.now() - 5045001,
                    },
                    {
                        title: '13. Odmiana czasowników'
                        , score: 40.3
                        , id: 'CH-2-L-12'
                        , timestamp: Date.now() - 5009901,
                    },
                    {
                        title: '14. Odmiana czasowników'
                        , score: 40.3
                        , id: 'CH-2-L-13'
                        , timestamp: Date.now() - 54540001,
                    },
                    {
                        title: '15. Odmiana czasowników'
                        , score: 40.3
                        , id: 'CH-2-L-14'
                        , timestamp: Date.now() - 50347001,
                    },
                ]
            } as Model.Chapter,
        ]


        const translations = [
            {
                left: ["Hallo"],
                right: ["Cześć"],
            } as Model.Translation,
            {
                left: ["Guten Morgen"],
                right: ["Dzień dobry (przed godziną 12)"],
            },
            {
                left: ["Guten Tag"],
                right: ["Dzień dobry (po godzinie 12)"],
            },
            {
                left: ["Guten Abend"],
                right: ["Dobry wieczór"]
            },
            {
                left: ["Servus"],
                right: ["Cześć"],
            },
            {
                left: ["Tschüs"],
                right: ["Cześć (na pożegnanie)"],
            },
            {
                left: ["Wie geht es dir?"],
                right: ["Jak się masz?"],
            },
            {
                left: ["Wie geht es Ihnen?"],
                right: ["Jak się ma Pan/Pani/Państwo?"]
            },
            {
                left: ["Wie geht's?"],
                right: ["Jak leci?", "Co słychać?"],
            },
        ] as Array<Model.Translation>

        const conjugations = [
            {
                title: "być",
                base: "sein",
                tense: 0,
                helper: null,
                conj: ["bin", "bist", "ist", "sind", "seid", "sind"],
                examples: [{
                    left: "Ich bin Paweł",
                    right: "Jestem Paweł",
                }],
            } as Model.Conjugation,
            {
                title: "mieć",
                base: "haben",
                tense: 0,
                helper: null,
                conj: ["habe", "hast", "hat", "haben", "habt", "haben"],
                examples: [
                    {
                        left: "Ich haben Geld",
                        right: "Mam pieniądze",
                    },
                    {
                        left: "Wir haben Cola",
                        right: "Mamy colę"
                    }],
            }
        ] as Array<Model.Conjugation>


        export const lessonData =
            {
                title: 'Odmiana czasowników'
                , score: 40.3
                , id: 'CH-1-L-1'
                , timestamp: Date.now() - 50000
                , translations: translations
                , conjugations: conjugations
            } as Model.LessonData
    }

    export const fetchChapters = async () => {
        return Example.chapters;
    }

    export const fetchChapter = async (id: string) => {
        if (id == "CH-1") return Example.chapters[0];
        if (id == "CH-2") return Example.chapters[1];
        throw Error('cannot fetch')
    }

    export const fetchLessonData = async (chapter_id: string, lesson_id: string) => {
        return Example.lessonData;
    }

}
