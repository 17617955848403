import React from 'react';
import { Outlet } from 'react-router-dom';
import { Layout } from 'antd';
import { SideMenu } from './components';

import type { MenuProps } from 'antd';


const headerStyle: React.CSSProperties = {
  textAlign: 'center',
  backgroundColor: '#7dbcea',
  height: '20pt',
}

const siderStyle: React.CSSProperties = {
  textAlign: 'center',
  lineHeight: '120px',
  color: '#fff',
  backgroundColor: 'white',
  height: 'calc(100vh - 70pt)',
};


const contentStyle: React.CSSProperties = {
  textAlign: 'left',
  minHeight: 120,
  lineHeight: '120px',
  padding: '10px',
  //color: '#fff',
  //backgroundColor: '#108ee9',
};




export function PageLayout() {
  return (
    <div className="App">
      <Layout>
        <Layout.Header style={headerStyle}>
        </Layout.Header>
        <Layout>
          <Layout.Sider style={siderStyle}>
            <SideMenu />
          </Layout.Sider>
          <Layout>
            <Layout.Content style={contentStyle}>
              <Outlet />
            </Layout.Content>
          </Layout>
        </Layout>
      </Layout>
    </div>
  );
}
