import { Typography, Card, Statistic } from 'antd';


export function WordsPage() {
    return <>
        <Typography.Title level={2}>
            Słówka
        </Typography.Title>
    </>
}
