import { Typography, Spin, Tabs, Tooltip, Card, Form, Input, Button, DatePicker, Space, Breadcrumb, Table, List } from 'antd';
import { Model } from '../api';
import { Link, useParams } from 'react-router-dom';
import { LessonsCardList } from '../components';
import { useFetchChapter, useFetchLessonData } from '../hooks';
import dayjs from 'dayjs';
import { JsxElement } from 'typescript';
import { PlusOutlined, AuditOutlined } from '@ant-design/icons';
import { Divider } from 'antd';

function TabLesson(props: { lessonData: Model.LessonData }) {
    return <Space>
        <Button type='primary'>Nauka</Button>
        <Button>Szybki test</Button>
    </Space>
}

function TabReport(props: { lessonData: Model.LessonData }) {
    return <b>e</b>
}

function TabDeclination(props: { lessonData: Model.LessonData }) {
    return <b>Nie gotowe</b>
}

function renderStrings(strings: string[]) {
    let f = (s: string, i: number) => {
        return <li key={i}>{s}</li>
    }
    return <ul>{strings.map(f)}</ul>
}

function renderAction() {
    return (
        <Space direction='horizontal'>
            <Button type="link">Edytuj</Button>
            <Button type="link" danger>Usuń</Button>
        </Space>
    );
}

function renderAction2() {
    return (
        <Space direction='horizontal'>
            <Button type="link">Pokaż</Button>
            <Button type="link" danger>Usuń</Button>
        </Space>
    );
}

function TabTranslations(props: { lessonData: Model.LessonData }) {
    let translations = props.lessonData.translations;

    return (
        <>
            <Space direction='horizontal'>
                <Button type="primary">Nauka</Button>
                <Button>Test</Button>
                <Button icon={<PlusOutlined />}>Dodaj</Button>
                <Divider/>
            </Space>
            <Table dataSource={translations}>
                <Table.Column title="Niemiecki" dataIndex="left" key="left" render={renderStrings} />
                <Table.Column title="Polski" dataIndex="right" key="right" render={renderStrings} />
                <Table.Column title="Akcje" key="action" render={renderAction} />
            </Table>
        </>
    );

    return (
        <Space direction='vertical'>
            <Table dataSource={translations}>
                <Table.Column title="Niemiecki" dataIndex="left" key="left" render={renderStrings} />
                <Table.Column title="Polski" dataIndex="right" key="right" render={renderStrings} />
                <Table.Column title="Akcje" key="action" render={renderAction} />
            </Table>
        </Space>
    );
}

function renderTense(x: number) {
    switch (x) {
        case 0:
            return "Teraźniejszy/Präsens"
    }
}

function renderConj(xs: string[]) {
    return (
        <>
            <i>Ich</i> <b>{xs[0]}</b>;&nbsp;&nbsp;
            <i>Du</i> <b>{xs[1]}</b>;&nbsp;&nbsp;
            <i>Er/He/It</i> <b>{xs[2]}</b>;&nbsp;&nbsp;
            <i>Mir</i> <b>{xs[3]}</b>;&nbsp;&nbsp;
            <i>Wir</i> <b>{xs[4]}</b>;&nbsp;&nbsp;
            <i>sie/Sie</i> <b>{xs[5]}</b>
        </>
    )
}

function renderExamples(xs: Array<Model.SimpleTranslation>) {
    return (<>
        {xs.length}
    </>);
}

function TabConjugation(props: { lessonData: Model.LessonData }) {
    let conjugation = props.lessonData.conjugations;

    return (
        <>
            <Space direction='horizontal'>
                <Button type="primary">Nauka</Button>
                <Button>Test</Button>
                <Button icon={<PlusOutlined />}>Dodaj</Button>
                <Divider/>
            </Space>
            <Table dataSource={conjugation} bordered>
                <Table.ColumnGroup title="Bezokolicznik">
                    <Table.Column title="Polski" key="title" dataIndex="title" />
                    <Table.Column title="Niemiecki" key="base" dataIndex="base" />
                </Table.ColumnGroup>
                <Table.Column title="Czas" key="tense" dataIndex="tense" render={renderTense} />
                <Table.Column title="Koniugacja" key="conj" dataIndex="conj" render={renderConj} />
                <Table.Column title="Ilość przykładów" key="examples" dataIndex="examples" render={renderExamples} />
                <Table.Column title="Akcje" key="action" render={renderAction2} />
            </Table>
        </>
    );
}

function CARD(l: JSX.Element) {
    return <Card>{l}</Card>
}

function TabEdit(props: { lessonData: Model.LessonData }) {
    let lessonData = props.lessonData;
    return (
        <Form labelCol={{ span: 1 }} initialValues={{ remember: true }} >
            <Form.Item label="Tytuł">
                <Input value={lessonData.title} />
            </Form.Item>
            <Form.Item label="Dataaa">
                <DatePicker value={dayjs(lessonData.timestamp)} />
            </Form.Item>
            <Form.Item label="">
                <Space direction='horizontal'>
                    <Button type="primary">Uaktualnij</Button>
                    <Button>Zresetuj</Button>
                </Space>
            </Form.Item>
        </Form>
    );
}

function TABLABEL(label: string, tooltip: string) {
    return <Tooltip title={tooltip}>{label}</Tooltip>
}

function TabView(props: { lessonData: Model.LessonData }) {

    let items = [
        {
            label: TABLABEL("Lekcja", "Opis lekcji"),
            key: '1',
            children: CARD(<TabLesson lessonData={props.lessonData} />)
        },
        {
            label: TABLABEL("Tłumaczenia", "Zestaw tłumaczeń zdań/wyrazów w danej lekcji"),
            key: '2',
            children: CARD(<TabTranslations lessonData={props.lessonData} />)
        },
        {
            label: TABLABEL('Koniugacja', "Zestaw odmian czasowników w danej lekcji"),
            key: '3',
            children: CARD(<TabConjugation lessonData={props.lessonData} />)
        },
        {
            label: TABLABEL('Deklinacja', "Zestaw odmian rzeczowników w danej lekcji"),
            key: '4',
            children: CARD(<TabDeclination lessonData={props.lessonData} />),
            disabled: true,
        },
        {
            label: TABLABEL("Raport", "Ewaluacja znajomości danej lekcji"),
            key: '5',
            children: CARD(<TabReport lessonData={props.lessonData} />)
        },
        {
            label: TABLABEL("Edycja", "Edycja własności lekcji"),
            key: '6',
            children: CARD(<TabEdit lessonData={props.lessonData} />)
        },
    ];


    return <Tabs defaultActiveKey='1' items={items} />
    //return 
}

function Content(props: { lessonData: Model.LessonData }) {

    return <TabView lessonData={props.lessonData} />
}

export function LessonPage() {
    let { chapter_id, lesson_id } = useParams();

    if (chapter_id === undefined || lesson_id === undefined) {
        throw Error('invalid url');
    }

    let {
        data: chapter
    } = useFetchChapter(chapter_id)

    let {
        isLoading: isLessonDataLoading,
        error: errorLoadingLessonData,
        data: lessonData
    } = useFetchLessonData(chapter_id, lesson_id);

    if (isLessonDataLoading || lessonData === undefined) {
        return <Spin tip="Wczytuję..." size='large' />
    }
    return <>
        <Typography.Title level={2}>
            Lekcja: {lessonData.title}
        </Typography.Title>
        <Breadcrumb>
            <Breadcrumb.Item>
                <Link to="/chapters">Rozdziały</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
                <Link to={`/chapter/${chapter?.id}`}>{chapter?.title}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
                {lessonData.title}
            </Breadcrumb.Item>
        </Breadcrumb>
        <Content lessonData={lessonData} />
    </>
}
