import { Typography, Card, List, Button, Divider } from 'antd';
import { Progress, Space, Segmented, Row, Col, Tooltip } from 'antd';
import { PlusOutlined, AuditOutlined } from '@ant-design/icons';
import { Model } from '../api';
import { LessonsService } from '../api';
import { Link, useParams } from 'react-router-dom';
import { useCallback, useMemo, useState } from 'react';
import { SegmentedValue } from 'antd/es/segmented';

interface Extractor<T> {
    score(x: T): number,
    title(x: T): string,
    openurl(x: T): string,
    testurl(x: T): string,
    addbutton(): string,
    timestamp(x: T): number,
}

function Item<T>(props: { extractor: Extractor<T>, item: T }) {

    let title = props.extractor.title(props.item);
    let score = props.extractor.score(props.item);
    let openurl = props.extractor.openurl(props.item);
    let testurl = props.extractor.testurl(props.item);

    let extra = (
        <Tooltip title="Ocena znajomości słówek">
            <Progress width={100} percent={score} type="circle" />
        </Tooltip>
    );

    return (<List.Item>
        <Card title={title} style={{ 'width': '250px' }}>
            <Row justify="center">
                <Col flex="100px">
                    <div style={{ 'textAlign': 'right' }}>
                        {extra}
                    </div>
                </Col>
            </Row>
            <Divider />
            <Row justify="center">
                <Space>
                    <Link to={openurl}>
                        <Button type="primary">Otwórz</Button>
                    </Link>
                    <Link to={testurl}>
                        <Button>Szybki test</Button>
                    </Link>
                </Space>
            </Row>
        </Card>
    </List.Item>
    );
}

function ScoredCardList<T>(props: { extractor: Extractor<T>, data: Array<T> }) {
    let extractor = props.extractor;
    let addbutton = extractor.addbutton();

    let [data, setData] = useState(() => props.data);

    let sortby = [
        {
            label: 'Najnowsze'
            , value: 'by-date'
        },
        {
            label: 'Najstarsze'
            , value: 'rev-by-date'
        },
        {
            label: 'Najgorsza ocena'
            , value: 'by-score'
        },
        {
            label: 'Najlepsza ocena'
            , value: 'rev-by-score'
        },
    ]

    let onChange = (value: SegmentedValue) => {
        let cmps = {
            'by-date': (a: T, b: T) => {
                return extractor.timestamp(b) - extractor.timestamp(a);
            },
            'rev-by-date': (a: T, b: T) => {
                return extractor.timestamp(a) - extractor.timestamp(b);
            },
            'by-score': (a: T, b: T) => {
                return extractor.score(a) - extractor.score(b);
            },
            'rev-by-score': (a: T, b: T) => {
                return extractor.score(b) - extractor.score(a);
            }
        }
        let cmp = cmps[value.toString() as keyof typeof cmps];

        setData(props.data.sort(cmp).slice(0));
        console.log(props.data);
    }

    let header = (<Space direction='horizontal'>
        <Segmented options={sortby} onChange={onChange} />
        <Button type='primary' icon={<PlusOutlined />}>{addbutton}</Button>
        <Button icon={<AuditOutlined/>}>Szybki test</Button>
    </Space>);

    let renderItem = useCallback((item: any) => {
        return <Item extractor={extractor} item={item} />
    }, [])

    return (
        <List grid={{ gutter: 0 }} size='small' dataSource={data} header={header} renderItem={renderItem} itemLayout='vertical' split={false} />
    );
}

type Lesson = Model.Lesson;
type Chapter = Model.Chapter;

class LessonExtractor implements Extractor<Lesson> {
    chapter_id : string

    constructor(chapter_id : string) {
        this.chapter_id = chapter_id

    }

    score(x: Lesson) { return x.score }
    title(x: Lesson) { return x.title }
    timestamp(x: Lesson) { return x.timestamp }
    openurl(x: Lesson) { return `/lesson/${this.chapter_id}/${x.id}` }
    addbutton() { return "Dodaj lekcję" }
    testurl(x: Lesson) { return `/` }
}

export function LessonsCardList(props: { chapter_id : string, lessons: Array<Lesson> }) {
    let [extractor, _] = useState(() => new LessonExtractor(props.chapter_id));
    return <ScoredCardList extractor={extractor} data={props.lessons} />;
}

class ChapterExtractor implements Extractor<Chapter> {
    score(x: Chapter) { return Model.Chapter__score(x) }
    title(x: Chapter) { return x.title }
    timestamp(x: Chapter) { return x.timestamp }
    openurl(x: Chapter) { return `/chapter/${x.id}` }
    testurl(x: Chapter) { return `/` }
    addbutton() { return "Dodaj rozdział" }
}

export function ChaptersCardList(props: { chapters: Array<Chapter> }) {
    let [extractor, _] = useState(() => new ChapterExtractor());
    return <ScoredCardList extractor={extractor} data={props.chapters} />;
}