

export namespace Model {

    export interface Lesson {
        id: string,
        title: string,
        score: number,
        timestamp : number,
    }

    export interface Chapter {
        id : string,
        title: string,
        timestamp : number,
        lessons: Array<Lesson>,
    }

    export interface Translation {
        left : Array<string>,
        right : Array<string>,
    }

    export interface SimpleTranslation {
        left : string,
        right : string,
    }

    export interface Conjugation {
        title : string,
        tense : number,
        base : string,
        helper : string|null,
        conj : Array<string>,
        examples : Array<SimpleTranslation>,
    }

    export interface Declination {
        left : string,
        right: string,
    }

    export interface LessonData extends Lesson {
        translations : Array<Translation>
        conjugations : Array<Conjugation>
        declinations : Array<Declination>
    }

    export const Chapter__score = (ch : Chapter) => {
        let score = 0.0;
        for (let ls of ch.lessons) {
            score += ls.score;
        }
        score /= ch.lessons.length;
        return Math.round(score);
    }

}