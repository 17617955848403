import { Router } from './components'
import { App as X } from 'antd'
import { QueryClient, QueryClientProvider } from 'react-query';


let queryClient = new QueryClient({})

export function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <X>
        <Router />
      </X>
    </QueryClientProvider>
  )
}
