import { useQuery } from "react-query";

import { LessonsService } from "../api";

export const useFetchChapters = () => useQuery(["chapters"], LessonsService.fetchChapters);

export const useFetchChapter = (id: string) => {
    return useQuery([`chapter${id}`], () => LessonsService.fetchChapter(id))
}

export const useFetchLessonData = (chapter_id: string, lesson_id: string) => {
    return useQuery([`chapter${chapter_id}_lesson${lesson_id}`], () => LessonsService.fetchLessonData(chapter_id, lesson_id))
}