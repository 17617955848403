import { Typography, Spin, Tabs, Tooltip, Card, Form, Input, Button, DatePicker, Space, Breadcrumb } from 'antd';
import { Model } from '../api';
import { Link, useParams } from 'react-router-dom';
import { LessonsCardList } from '../components';
import { useFetchChapter } from '../hooks';
import dayjs from 'dayjs';

function TabLessons(props: { chapter: Model.Chapter }) {
    return <LessonsCardList chapter_id={props.chapter.id} lessons={props.chapter.lessons} />;
}

function TabReport(props: { chapter: Model.Chapter }) {
    return <b>tab report</b>
}

function TabEdit(props: { chapter: Model.Chapter }) {
    let chapter = props.chapter;
    return <Card>
        <Form labelCol={{ span: 1 }} initialValues={{ remember: true }} >
            <Form.Item label="Tytuł">
                <Input value={chapter.title} />
            </Form.Item>
            <Form.Item label="Dataaa">
                <DatePicker value={dayjs(chapter.timestamp)} />
            </Form.Item>
            <Form.Item label="">
                <Space direction='horizontal'>
                    <Button type="primary">Uaktualnij</Button>
                    <Button>Zresetuj</Button>
                </Space>
            </Form.Item>
        </Form>
    </Card>
}


function TabView(props: { chapter: Model.Chapter }) {

    let items = [
        {
            label: <Tooltip title="Zbiór lekcji">Lekcje</Tooltip>,
            key: '1',
            children: <TabLessons chapter={props.chapter} />
        },
        {
            label: <Tooltip title="Ewaluacja znajomości">Raport</Tooltip>,
            key: '2',
            children: <TabReport chapter={props.chapter} />
        },
        {
            label: <Tooltip title="Edycja rozdziału">Edycja</Tooltip>,
            key: '3',
            children: <TabEdit chapter={props.chapter} />
        },
    ];


    return <Tabs defaultActiveKey='1' items={items} />
    //return 
}

function Content(props: { chapter: Model.Chapter }) {

    return <TabView chapter={props.chapter} />
}

export function ChapterPage() {
    let { chapter_id } = useParams();

    if (chapter_id === undefined) {
        throw Error('invalid url');
    }

    let {
        isLoading: isChapterLoading,
        error: errorLoadingChapter,
        data: chapter
    } = useFetchChapter(chapter_id);

    if (isChapterLoading || chapter === undefined) {
        return <Spin tip="Wczytuję..." size='large' />
    }
    return <>
        <Typography.Title level={2}>
            Rozdział: {chapter.title}
        </Typography.Title>
        <Breadcrumb>
            <Breadcrumb.Item>
            <Link to="/chapters">Rozdziały</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
            {chapter.title}
            </Breadcrumb.Item>
        </Breadcrumb>
        <Content chapter={chapter} />
    </>
}