import { BrowserRouter, NavLink, Outlet, Route, Routes } from 'react-router-dom';
import { PageLayout } from '../Layout'
import { HomePage, ChaptersPage, ChapterPage, ReportPage, WordsPage, LessonPage } from '../../pages';
function T() {
    return <b>T</b>
}


export function Router() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<PageLayout />}>
                    <Route path="" element={<HomePage/>} />
                    <Route path="/chapters" element={<ChaptersPage />} />
                    <Route path="/chapter/:chapter_id" element={<ChapterPage />} />
                    <Route path="/lesson/:chapter_id/:lesson_id" element={<LessonPage />} />
                    <Route path="/report" element={<ReportPage/>} />
                    <Route path="/words" element={<WordsPage/>} />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}
